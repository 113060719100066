import { template as template_7956b85b37a3467c811544e8a9f745c0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_7956b85b37a3467c811544e8a9f745c0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
