import { template as template_1a3fac17015f4c92bf6b68e642470468 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1a3fac17015f4c92bf6b68e642470468(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
