import { template as template_87821f2892364c41bd1ada33390beed8 } from "@ember/template-compiler";
const FKText = template_87821f2892364c41bd1ada33390beed8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
